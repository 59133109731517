import { useState, useEffect } from "react";
import { swalAlert } from "../../helpers/swalAlert";
import { SendToCentral, GetVacancyDocuments } from "../../services/api";
import { getMostRecentDocument } from "../../helpers/documentHelpers";
import { useNavigate } from "react-router-dom";
import useDocumentLink from "../../hooks/useDocumentLink";
import useIsLinkRoute from "../../hooks/useIsLinkRoute";
import { IDocuments } from "../../@types/Documents";

const useDocumentValidation = (
  nrChamado: any,
  vacancyDetails: any,
  setAlert: any,
  setDocuments: any
) => {
  const [isSending, setIsSending] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [documents, setDocumentsState] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [sentDocuments, setSentDocuments] = useState(0);
  const [attachedDocuments, setAttachedDocuments] = useState(0);
  const navigate = useNavigate();
  const { user } = useDocumentLink();
  const isLinkRoute = useIsLinkRoute();

  const getDocuments = async () => {
    setIsRefreshing(true);
    setAlert({ waitingResponse: true });
    const response = await GetVacancyDocuments(
      nrChamado || user.nrChamado || sessionStorage.getItem("nrChamadoLink")
    );
    if (response?.status >= 200 && response?.status < 300) {
      setTotalDocuments(response.data.qtdeDocumentoTotal);
      setAttachedDocuments(response.data.qtdeDocumentoAnexado);
      setSentDocuments(response.data.qtdeDocumentoEnviadoCentral);
      setDocumentsState(response.data.listaDocumentosFormatada);
      setDocuments(response.data.listaDocumentosFormatada);
      setAlert({ waitingResponse: false });
    } else {
      swalAlert("error", response, "TENTAR NOVAMENTE");
      setTimeout(
        () =>
          navigate(
            isLinkRoute ? "/link/documentos" : "/auth/vagas-em-andamento"
          ),
        2000
      );
    }
    setIsRefreshing(false);
  };

  useEffect(() => {
    getDocuments();
  }, [nrChamado]);

  const sendDocuments = async () => {
    setIsSending(true);
    setAlert({ waitingResponse: true });
    const response = await SendToCentral(vacancyDetails?.nrChamado);
    if (response?.status >= 200 && response?.status < 300) {
      swalAlert("success", "Documentos enviados com sucesso!", "OK, ENTENDI");
      setAlert({ waitingResponse: false });
    } else {
      swalAlert("error", response, "TENTAR NOVAMENTE");
      setAlert({ waitingResponse: false });
    }
    setIsSending(false);
    await getDocuments();
  };

  const areAllMandatoryDocumentsApproved = (documents: any) => {
    for (const document of documents) {
      const mostRecentDocument = getMostRecentDocument(document);
      if (
        mostRecentDocument &&
        (mostRecentDocument.status === "Reprovado" ||
          mostRecentDocument.status === "Aguardando Textract" ||
          (document.statusDoUsuario === "Documento Pendente" &&
            document.justificativa))
      ) {
        return false;
      }
      if (
        document.obrigatorio &&
        document.statusDoUsuario !== "Documento Enviado" &&
        (!mostRecentDocument ||
          !["Aprovado", "Aprovado Sem Textract", "Aprovado Textract"].includes(
            mostRecentDocument.status
          ))
      ) {
        return false;
      }
    }
    return true;
  };

  const hasDocumentAttached = (documents: IDocuments[]): boolean => {
    for (const document of documents) {
      if (document.statusDoUsuario === "Documento Anexado") {
        return true;
      }
    }
    return false;
  };

  /* const hasDocumentSent = (documents: IDocuments[]): boolean => {
    for (const document of documents) {
      if (document.statusDoUsuario === "Documento Enviado") {
        return true;
      }
    }
    return false;
  }; */

  const hasAllDocumentSent = (documents: IDocuments[]): boolean => {
    console.log("hasAllDocumentSent", documents);
    return documents.every(
      (document) => document.statusDoUsuario === "Documento Enviado"
    );
  };

  const disableSentToCentralButton = () => {
    const toCentral = sentDocuments + attachedDocuments;
    if (
      sentDocuments >= totalDocuments ||
      attachedDocuments >= totalDocuments ||
      toCentral >= totalDocuments
    ) {
      return false;
    } else {
      return true;
    }
  };
  const isSendButtonDisabled = () => {
    return (
      !areAllMandatoryDocumentsApproved(documents) ||
      disableSentToCentralButton() ||
      isSending
    );
  };

  return {
    sendDocuments,
    isSendButtonDisabled,
    hasAllDocumentSent,
    hasDocumentAttached,
    isSending,
    documents,
    isRefreshing,
  };
};

export default useDocumentValidation;
