import { makeStyles } from "@mui/styles";
import { Typography, Container, Grid, Skeleton } from "@mui/material";
import { Check, ChevronRight } from '@mui/icons-material';
import ConfirmButton from "../../components/ConfirmButton";
import { useContext, useEffect, useState } from "react";
import 'swiper/css';
import { useNavigate } from "react-router-dom";
import TitleVacancyDetails from "../../components/TitleVacancyDetails";
import useDocumentLink from "../../hooks/useDocumentLink";
import { swalAlert } from "../../helpers/swalAlert";
import { MdChecklist } from "react-icons/md";
import useAlert from "../../hooks/useAlert";
import GoBackHeader from "../../components/GoBackHeader";
import { FichaContext } from "../../context/FichaContext";
import { useFetchVacancyDetail } from "./FichaCadastral/useFetchVacancyDetail";
import useFetchEmployeeData from "./FichaCadastral/useFetchEmployeeData";
import useDocumentValidation from "./useDocumentValidation";
import { IDocuments } from "../../@types/Documents";
import {
  checkDadosPessoais,
  checkCarteiraDeTrabalho,
  checkCertificadoReservista,
  checkDadosBancarios,
  checkDocumentoDeIdentidade,
  checkEndereco,
  checkEstadoCivil,
  checkEtnia,
  checkGeneroOrientacao,
  checkGrauInstrucao,
  checkInformacoesContato,
  checkPessoaComDeficiencia,
  checkTituloDeEleitor,
  checkValeTransporte,
  checkDependentes
} from './FichaCadastral/checkFieldsFilles';

const HomeLink = () => {
  const useStyles = makeStyles(theme => ({
    containerHome: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'stretch',
      height: '100vh',
      padding: 0,
    },
    contentHome: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 16,
      marginRight: 16,
      marginBottom: 67,
    },
    titleContentHome: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'start',
      marginBottom: 24,
    },
    titleHome: {
      fontSize: 20,
      lineHeight: "30px",
      fontWeight: 600,
      color: '#1d3054'
    },
    descriptionHome: {
      fontSize: 14,
      lineHeight: "20px",
      color: '#4d4d4d'
    },
    sliderContainerHome: {
      maxWidth: 412,
      width: '91vw',
      marginTop: 16,
      marginBottom: 16,
    },
    cardHome: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: '#fff',
      padding: 16,
      borderRadius: 8,
      boxShadow: '0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)',
      marginBottom: 8,
      textDecoration: 'none',
      color: 'inherit',
    },
    iconHome: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#e8e8e8',
      height: 40,
      width: 40,
      borderRadius: 4,
    },
    cardTitleHome: {
      fontSize: 14,
      fontWeight: 700,
      color: '#1d3054',
    },
    cardSubtitleHome: {
      fontSize: 12,
      color: '#4d4d4d',
    },
    chevronContainerHome: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 4,
    },
    cardClickableHome: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    textContainerHome: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginLeft: 8,
      marginRight: 8,
    },
    documentsSent: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      color: "#15803D",
      background: "#DCFCE7",
      justifyContent: "center",
    },
  }));

  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const { user } = useDocumentLink();
  const nrChamado = user.nrChamado || sessionStorage.getItem("nrChamadoLink");
  const [vacancyDetails, setVacancyDetails] = useState<any>({});
  const fichaContext = useContext(FichaContext);
  const { alert, setAlert } = useAlert();
  const [documents, setDocuments] = useState<IDocuments[]>([]);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const { sendDocuments, isSendButtonDisabled, isSending, hasAllDocumentSent, hasDocumentAttached } = useDocumentValidation(nrChamado, vacancyDetails, setAlert, setDocuments);

  useFetchVacancyDetail(nrChamado, setVacancyDetails, vacancyDetails, setLoading, loading);
  useFetchEmployeeData(vacancyDetails, fichaContext);

  useEffect(() => {
    setIsButtonEnabled(buttonEnabled());
  }, []);

  if (!fichaContext) {
    return <div>O contexto de ficha não está disponível.</div>;
  }

  const fichaEnviada = () => {
    const { fichaCadastralEnviada } = fichaContext.ficha;
    return fichaCadastralEnviada === "true";
  };

  const allFilledCheck = () => {
    return [
      checkDadosPessoais,
      checkGeneroOrientacao,
      checkEstadoCivil,
      checkEtnia,
      checkGrauInstrucao,
      checkPessoaComDeficiencia,
      checkEndereco,
      checkInformacoesContato,
      checkDependentes,
      checkDadosBancarios,
      checkValeTransporte,
      checkCarteiraDeTrabalho,
      checkDocumentoDeIdentidade,
      checkTituloDeEleitor,
      checkCertificadoReservista
    ].every(checkFunction => checkFunction(fichaContext.ficha));
  };

  const buttonEnabled = () => {
    return fichaEnviada() && allFilledCheck() && !isSendButtonDisabled();
  };



  const CardClick = ({ filled, title, subtitle, onClick }: { filled: any, title: any, subtitle: any, onClick: () => any }) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onClick();
      }
    };

    return (
      <div
        className={classes.cardHome}
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <Grid item className={classes.iconHome}>
          {filled ? (
            <Check style={{ color: '#15803D', fontSize: 20, backgroundColor: '#F0FDF4', width: 40, height: 40, borderRadius: 4, padding: 8 }} />
          ) : (
            <MdChecklist style={{ color: '#1d3054', fontSize: 20, width: 40, height: 40, borderRadius: 4, padding: 8 }} />
          )}
        </Grid>
        <div className={classes.cardClickableHome}>
          <div className={classes.textContainerHome}>
            <Typography variant="body2" className={classes.cardTitleHome}>
              {title}
            </Typography>
            <Typography variant="body2" className={classes.cardSubtitleHome}>
              {subtitle}
            </Typography>
          </div>
          <div className={classes.chevronContainerHome}>
            <ChevronRight style={{ color: '#1d3054' }} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="xs" className={classes.containerHome}>
      <Grid style={{ marginBottom: 16 }}>
        <GoBackHeader
          onGoBack={() => (navigate('/link/'))}
          disabled
        />
      </Grid>
      <div className={classes.contentHome}>
        <div className={classes.titleContentHome}>
          <Typography variant="h6" className={classes.titleHome}>
            Cadastro de funcionário
          </Typography>
          <Typography variant="body2" className={classes.descriptionHome}>
            Preencha a ficha cadastral e envie seus documentos
          </Typography>
        </div>

        {loading ? (
          <Skeleton variant="rectangular" width={'100%'} height={160} />
        ) : (
          <TitleVacancyDetails
            vacancyDetails={vacancyDetails}
            disableProgress={true}
          />
        )}
        {hasAllDocumentSent(documents) ? (
          <span className={classes.documentsSent}>Documentos enviados com sucesso!</span>
        ) : (
          null
        )}
        {loading ? (
          <Grid container spacing={2}>
            {[...Array(2)].map((_, index) => (
              <Grid item width={'100%'} key={index}>
                <Skeleton variant="rectangular" height={60} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <div className={classes.sliderContainerHome}>

            <CardClick filled={allFilledCheck() && fichaEnviada()} title="Ficha Cadastral" subtitle="Etapa obrigatória *" onClick={() => navigate('/link/ficha-cadastral')} />

            <CardClick filled={!isSendButtonDisabled()} title="Envio de documentos" subtitle="Etapa obrigatória *" onClick={() => navigate('/link/documentos')} />

          </div>
        )}

        {/* {loading ? (
          <Skeleton variant="rectangular" width={'100%'} height={160} />
        ) : (
          hasAllDocumentSent(documents) ? (
            <ConfirmButton disabled={true} onClick={sendDocuments}>
              DOCUMENTOS ENVIADOS PARA CENTRAL
            </ConfirmButton>
          ) : (
            <ConfirmButton disabled={!isButtonEnabled} onClick={sendDocuments}>
              {isSending ? "Enviando para a central..." : "ENVIAR PARA CENTRAL"}
            </ConfirmButton>
          )
        )} */}

        {loading ? (
          <Skeleton width={'100%'} height={160} />
        ) : (
          hasDocumentAttached(documents) ?
            (
              <ConfirmButton disabled={!isButtonEnabled || isSending} onClick={sendDocuments}>
                {isSending ? "Enviando para a central..." : "ENVIAR PARA CENTRAL"}
              </ConfirmButton>
            )
            :
            (
              <ConfirmButton disabled={true} onClick={sendDocuments}>
                DOCUMENTOS ENVIADOS PARA CENTRAL
              </ConfirmButton>
            )
        )}
      </div>
    </Container>
  );
}

export default HomeLink;
